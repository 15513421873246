/* You can add global styles to this file, and also import other style files */

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@import "primeflex/primeflex.css";

@import "primeicons/primeicons.css";


:root{
  --main-albo: #263A7D;
  --secondary-albo: #43C0C2;
  --tertiary-albo: #3b82f6;

  --bg-white-albo: #f8fafc; // background dettagli e sidebar
  --bg-black-albo: #000000;
  --base-albo: #ffffff;
  --bg-th: #ffffff; // background header tabelle
}

body{
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

// Stile Scrollbar
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--main-albo);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-albo);
}

// GESTIONE COLORI PERSONALIZZATI DI PRIMENG
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--main-albo); //#3b82f6
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: var(--main-albo); //#3b82f6
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--main-albo); //#3b82f6
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: var(--main-albo); //#3b82f6
}
.p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
  border-color: var(--main-albo); //#3b82f6
}
.p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
  border-color: var(--main-albo); //#3b82f6
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  border-color: var(--main-albo); //#3b82f6
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: var(--main-albo); //#3b82f6
}
.p-datepicker table td > span.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: var(--main-albo); //#3b82f6
}
.p-cascadeselect:not(.p-disabled).p-focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--main-albo); //#3b82f6
  background: var(--main-albo); //#3b82f6
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--main-albo); //#3b82f6
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--secondary-albo); //#1D4ED8
  background: var(--secondary-albo); //#1D4ED8
}
.p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
  background: var(--main-albo); //#3b82f6
}
.p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--secondary-albo); //#1D4ED8
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--main-albo); //#3b82f6
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--secondary-albo); //#1D4ED8
}
.p-chips:not(.p-disabled):hover .p-chips-multiple-container {
  border-color: var(--main-albo); //#3b82f6
}
.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
  border-color: var(--main-albo); //#3b82f6
}
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--main-albo); //#3b82f6
}
.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--main-albo); //#3b82f6
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--main-albo); //#3b82f6
}
.p-inputtext:enabled:hover {
  border-color: var(--main-albo); //#3b82f6
}
.p-inputtext:enabled:focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-listbox.p-focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-multiselect:not(.p-disabled):hover {
  border-color: var(--main-albo); //#3b82f6
}
.p-multiselect:not(.p-disabled).p-focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--main-albo); //#3b82f6
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--main-albo); //#3b82f6
  background: var(--main-albo); //#3b82f6
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--secondary-albo); //#1D4ED8
  background: var(--secondary-albo); //#1D4ED8
}
.p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight {
  background: var(--main-albo); //#3b82f6
}
.p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--secondary-albo); //#1D4ED8
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--main-albo); //#3b82f6
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--secondary-albo); //#1D4ED8
}
.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: var(--main-albo); //#3b82f6
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
  color: var(--main-albo); //#3b82f6
}
.p-selectbutton .p-button.p-highlight {
  background: var(--main-albo); //#3b82f6
  border-color: var(--main-albo); //#3b82f6
}
.p-slider .p-slider-handle {
  border: 2px solid var(--main-albo); //#3b82f6
}
.p-slider .p-slider-range {
  background: var(--main-albo); //#3b82f6
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--main-albo); //#3b82f6
  border-color: var(--main-albo); //#3b82f6
}
.p-togglebutton.p-button.p-highlight {
  background: var(--main-albo); //#3b82f6
  border-color: var(--main-albo); //#3b82f6
}
.p-treeselect:not(.p-disabled):hover {
  border-color: var(--main-albo); //#3b82f6
}
.p-treeselect:not(.p-disabled).p-focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-button {
  background: var(--main-albo); //#3b82f6
  border: 1px solid var(--main-albo); //#3b82f6
}
.p-button:not(:disabled):hover {
  background: var(--main-albo); //#3b82f6
  border-color: var(--main-albo); //#3b82f6
}
.p-button:not(:disabled):active {
  background: var(--main-albo); //#3b82f6
  border-color: var(--main-albo); //#3b82f6
}
.p-button.p-button-outlined {
  color: var(--main-albo); //#3b82f6
}
.p-button.p-button-outlined:not(:disabled):hover {
  color: var(--main-albo); //#3b82f6
}
.p-button.p-button-outlined:not(:disabled):active {
  color: var(--main-albo); //#3b82f6
}
.p-button.p-button-text {
  color: var(--main-albo); //#3b82f6
}
.p-button.p-button-text:not(:disabled):hover {
  color: var(--main-albo); //#3b82f6
}
.p-button.p-button-text:not(:disabled):active {
  color: var(--main-albo); //#3b82f6
}
.p-button .p-badge {
  color: var(--main-albo); //#3b82f6
}
.p-button.p-button-link {
  color: var(--secondary-albo); //#1D4ED8
}
.p-button.p-button-link:not(:disabled):hover {
  color: var(--secondary-albo); //#1D4ED8
}
.p-button.p-button-link:not(:disabled):active {
  color: var(--secondary-albo); //#1D4ED8
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datatable .p-sortable-column.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datatable .p-sortable-column.p-highlight:hover {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datatable .p-column-resizer-helper {
  background: var(--main-albo); //#3b82f6
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: var(--secondary-albo); //#1D4ED8
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: var(--secondary-albo); //#1D4ED8
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  color: var(--secondary-albo); //#1D4ED8
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-orderlist .p-orderlist-list-container.p-focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-picklist .p-picklist-list-wrapper.p-focus {
  border-color: var(--main-albo); //#3b82f6
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--secondary-albo); //#1D4ED8
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: var(--secondary-albo); //#1D4ED8
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  color: var(--secondary-albo); //#1D4ED8
}
.p-treetable .p-sortable-column.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
  background: var(--main-albo); //#3b82f6
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--secondary-albo); //#1D4ED8
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  color: var(--secondary-albo); //#1D4ED8
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: var(--secondary-albo); //#1D4ED8
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: var(--secondary-albo); //#1D4ED8
}
.p-treetable .p-column-resizer-helper {
  background: var(--main-albo); //#3b82f6
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: var(--main-albo); //#3b82f6
  color: var(--main-albo); //#3b82f6
}
.p-tabview .p-tabview-nav-btn.p-link {
  color: var(--main-albo); //#3b82f6
}
.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  color: var(--secondary-albo); //#1D4ED8
}
.p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
  background-color: var(--main-albo); //#3b82f6
}
.p-stepper.p-stepper-vertical .p-stepper-panel:has(~ .p-stepper-panel-active) .p-stepper-separator {
  background-color: var(--main-albo); //#3b82f6
}
.p-overlaypanel .p-overlaypanel-close {
  background: var(--main-albo); //#3b82f6
}
.p-fileupload .p-fileupload-content.p-fileupload-highlight {
  border-color: 1px dashed var(--main-albo); //#3b82f6
}
.p-fileupload-choose:not(.p-disabled):active {
  background: var(--secondary-albo); //#1D4ED8
  border-color: var(--secondary-albo); //#1D4ED8
}
.p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content {
  color: var(--secondary-albo); //#1D4ED8
}
.p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: var(--secondary-albo); //#1D4ED8
}
.p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-megamenu .p-menuitem.p-highlight > .p-menuitem-content {
  color: var(--secondary-albo); //#1D4ED8
}
.p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: var(--secondary-albo); //#1D4ED8
}
.p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-menu .p-menuitem.p-highlight > .p-menuitem-content {
  color: var(--secondary-albo); //#1D4ED8
}
.p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: var(--secondary-albo); //#1D4ED8
}
.p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
.p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-menu .p-menuitem-badge {
  background: var(--main-albo); //#3b82f6
}
.p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
  color: var(--secondary-albo); //#1D4ED8
}
.p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: var(--secondary-albo); //#1D4ED8
}
.p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content {
  color: var(--secondary-albo); //#1D4ED8
}
.p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: var(--secondary-albo); //#1D4ED8
}
.p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
.p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content {
  color: var(--secondary-albo); //#1D4ED8
}
.p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: var(--secondary-albo); //#1D4ED8
}
.p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
.p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-slidemenu .p-menuitem-badge {
  background: var(--main-albo); //#3b82f6
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  color: var(--secondary-albo); //#1D4ED8
}
.p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
  background: var(--main-albo); //#3b82f6
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: var(--main-albo); //#3b82f6
  color: var(--main-albo); //#3b82f6
}
.p-tabmenu .p-tabmenu-nav-btn.p-link {
  color: var(--main-albo); //#3b82f6
}
.p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
  color: var(--secondary-albo); //#1D4ED8
}
.p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: var(--secondary-albo); //#1D4ED8
}
.p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
  color: var(--secondary-albo); //#1D4ED8
}
.p-inline-message.p-inline-message-info {
  border: solid var(--main-albo); //#3b82f6
  color: var(--main-albo); //#3b82f6
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: var(--main-albo); //#3b82f6
}
.p-message.p-message-info {
  border: solid var(--main-albo); //#3b82f6
  color: var(--main-albo); //#3b82f6
}
.p-message.p-message-info .p-message-icon {
  color: var(--main-albo); //#3b82f6
}
.p-message.p-message-info .p-message-close {
  color: var(--main-albo); //#3b82f6
}
.p-toast .p-toast-message.p-toast-message-info {
  border: solid var(--main-albo); //#3b82f6
  color: var(--main-albo); //#3b82f6
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: var(--main-albo); //#3b82f6
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  color: var(--secondary-albo); //#1D4ED8
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  color: var(--secondary-albo); //#1D4ED8
}
.p-badge {
  background: var(--main-albo); //#3b82f6
}
.p-metergroup .p-metergroup-meter {
  background: var(--main-albo); //#3b82f6
}
.p-metergroup .p-metergroup-labels .p-metergroup-label-marker {
  background: var(--main-albo); //#3b82f6
}
.p-progressbar .p-progressbar-value {
  background: var(--main-albo); //#3b82f6
}
.p-tag {
  background: var(--main-albo); //#3b82f6
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--main-albo); //#3b82f6
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background-color: var(--main-albo); //#3b82f6
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 var(--main-albo); //#3b82f6
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 var(--main-albo); //#3b82f6
}


.p-dropdown, .p-autocomplete-input, .p-inputnumber-input, .p-button, .p-checkbox-box{
  box-shadow: none;
}
.p-dialog, .p-dialog:hover{
  .p-dialog-header-icon{
    background-color: transparent;
    border-color: transparent;
  }
}
.denyButton{
  background-color: #C0392B;
  border-color: #C0392B;
  border-radius: 6px;
  .p-button{
    background-color: #C0392B;
    border-color: #C0392B;
  }
}
.denyButton:hover{
  background-color: rgb(190, 0, 0) !important;
  border-color: rgb(190, 0, 0) !important;
  .p-button{
    background-color: rgb(190, 0, 0) !important;
    border-color: rgb(190, 0, 0) !important;
  }
}
.acceptButton{
  background-color: green;
  border-color: green;
  border-radius: 6px;
  .p-button{
    background-color: green;
    border-color: green;
  }
}
.acceptButton:hover{
  background-color: rgb(0, 90, 0) !important;
  border-color: rgb(0, 90, 0) !important;
  .p-button{
    background-color: rgb(0, 90, 0) !important;
    border-color: rgb(0, 90, 0) !important;
  }
}
.pi-cog{
  color: var(--main-albo);
}